<template>
  <v-container fluid style="max-width: 1785px">
    <v-row dense>
      <v-col cols="12">
        <navigation-drawer />
        <v-card class="pa-5">
          <router-view />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavigationDrawer from "@/views/Panel/views/components/NavigationDrawer";
export default {
  name: "PanelIndex",
  components: { NavigationDrawer }
};
</script>
