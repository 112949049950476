<template>
  <v-card>
    <!--    <v-navigation-drawer permanent>-->
    <!--      <v-list dense nav>-->
    <!--        <v-list-item-->
    <!--              v-for="item in links"-->
    <!--              :key="item.title"-->
    <!--              link-->
    <!--              :to="{ name: item.to }"-->
    <!--        >-->
    <!--          <v-list-item-icon>-->
    <!--            <v-icon>{{ item.icon }}</v-icon>-->
    <!--          </v-list-item-icon>-->

    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ item.title }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--      </v-list>-->
    <!--    </v-navigation-drawer>-->
    <v-tabs background-color="secondary" dark icons-and-text>
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab
        v-for="item in links"
        :key="item.title"
        link
        :to="{ name: item.to }"
      >
        {{ item.title }}
        <v-icon>{{ item.icon }}</v-icon>
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavigationDrawer",
  data() {
    return {
      items: [
        // {
        // icon: "mdi-view-dashboard",
        // title: "Dashboard",
        // to: "PanelDashboard"
        // },
        {
          icon: "mdi-account-multiple",
          title: "Users",
          to: "PanelUsers"
        },
        {
          icon: "mdi-earth",
          title: "Markets",
          to: "PanelMarkets",
          roles: ["admin"]
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    }),
    links() {
      return this.items.filter(
        item => !item.roles || item.roles.includes(this.user.role)
      );
    }
  }
};
</script>
